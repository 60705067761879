<template>
  <div class="app-header flex" :style="{'height': height}">
    <div class="web-header w-full flex pt-6">
      <div class="button mr-6 retour" @click="goBack">
        <svg-icon
          name="arrow"
          class="h-4 cursor-pointer mr-4"
          original
        />
       <div class="mr-2">Retour</div>
      </div>

      <div class="retour">
        <div class="sous-titre" v-if="donnes === null">
          <span v-if="devis.type === 'Auto'">{{devis.marque}} | {{devis.plaque}}</span>
          <span v-if="devis.type === 'Moto'">{{devis.marque}} | {{devis.model}}</span>
          <span v-if="devis.type === 'Mrh'">{{devis.model.model}} </span>
        </div>

        <div class="sous-titre" v-if="donnes !== null">
          <span v-if="donnes.product.categorie === 'AUTO'">{{donnes.meta.carBrand}} | {{donnes.meta.carRegistration}}</span>
          <span v-if="donnes.product.categorie === 'MOTO'">{{donnes.meta.motorBrand}} | {{donnes.meta.motoRegistration}}</span>
          <span v-if="donnes.product.categorie === 'MRH'">{{devis.model.model}} </span>
        </div>

        <div class="titre mt-2">
          {{ titre }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'TheHeader',
  components: {
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },
    titre: {
      type: String,
      default: 'Récapitulatif'
    },
    height: {
      type: String,
      default: '279px'
    }
  },
  data () {
    return {
      devis: null
    }
  },
  mounted () {
    this.devis = this.$store.getters.trackFirstDevis
  },

  methods: {
    goBack () {
      this.$router.push('/')
    },
    accueil () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.app-header {
  width: 100%;
  background: linear-gradient(84.41deg, #49BF7C -58.44%, #0269AD 108.79%);
  z-index: 9;
  color: #000000;
  padding: 0px 300px 0px 250px;
  font-family: $font-default;
}
.titre{
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 34px;
  color: #FFFFFF;
}
.sous-titre{
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
  opacity: 0.5;
}
.button {
  cursor: pointer;
  background-color: $white;
  width: 192px;
  height: 60px;
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  border-radius: 15px;
  border: none;
  color: black;
}
.button:hover{
  background-color: #ebedee;
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .app-header {
    padding: 0px 300px 0px 170px;
  }
}
@media screen and (max-width: 769px) and (min-width: 701px) {
  .app-header {
    padding: 0px 20px 0px 50px;
  }
}
@media only screen and (max-width: 700px) {
  .app-header {
    padding: 0px 10px 0px 20px;
    height: 91px;
  }
  .retour{
    display: none;
  }
  .button{
    padding: 15px;
    width: 45px;
    height: 45px;
    border-radius: 11.25px;
  }
  .titre{
    font-size: 26px;
  }
  .sous-titre{
    font-size: 14px;
  }
  .titre{
    font-size: 18px;
  }
}

</style>
