<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          class=""
        >
          <spinner color="#ffffff"/>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import spinner from '@/components/helper/add/spinner'

export default {
  name: 'Success',
  components: { PopupBase, spinner },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      allSteep: ['Devis', 'Identification', 'Complèter...', 'Paiement']
    }
  },
  computed: {
  },

  methods: {
    fermer () {
      this.activeOk = false
      this.$emit('oga', false)
    },
    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
      this.activeOk = false
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 40%;
      font-family: 'Mulish';
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
      }
      .titre{
        font-size: 20px;
      }
      .bloc1{
        width: 100%;
      }
      .bloc2{
        width: 100%;
        font-size: 18px;
      }
      .button{
        width: 100%;
      }
    }
</style>
