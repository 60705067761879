<template>
  <div class="w-full h-full">
    <div class="denom mb-2">Pays d'immatriculation</div>

    <inputo :value="zone" @info="retourInfo" placeholder="Écrivez pour rechercher"/>
    <selectListe :option="allZone" @info="retourSearch" :value="zone" v-if="zone.length > 0 && selected !== zone"/>

    <div v-if="selected !== null">
      <div class="denom mt-5 mb-2">Numero d'immatriculation</div>

      <div v-if="verifType() === 1">
        <div class="input pl-5 h-full" v-if="selected === 'Bénin' || selected === 'Togo'">
           <div class="h-full">
             <div class="flex items-center h-full tout">
               <input type="text" ref="lettre" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" v-model="lettre" class=" lettre h-full" placeholder="BC" maxlength="02">
               <input type="text" ref="chiffre" @keypress="isNumberOnly" v-model="plaque" class="numero h-full" placeholder="2252" maxlength="04">
               <input type="text" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" ref="pays" v-model="pays" class="h-full neutre" placeholder="RB" maxlength="02">
             </div>

             <div class="flex items-center bas">
               <div class="mr-2 tiret" @click="setFocus1">--</div>
               <div class="mr-2 tiret" @click="setFocus2">----</div>
               <div class="tiret" @click="setFocus3">--</div>
             </div>
           </div>
        </div>

        <div class="input pl-5 h-full" v-if="selected === 'Niger'">
          <div class="h-full">
            <div class="flex items-center h-full tout">
              <input type="text" ref="lettre" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" v-model="lettre" class=" lettre h-full" placeholder="BC" maxlength="02">
              <input type="text" ref="chiffre" @keypress="isNumberOnly" v-model="plaque" class="numero h-full" placeholder="2252" maxlength="04">
              <input type="text" ref="pays" v-model="pays" class="h-full neutre" placeholder="RN8" maxlength="03">
            </div>

            <div class="flex items-center bas">
              <div class="mr-2 tiret" @click="setFocus1">--</div>
              <div class="mr-2 tiret" @click="setFocus2">----</div>
              <div class="tiret" @click="setFocus3">---</div>
            </div>
          </div>
        </div>

        <div class="input pl-5 h-full" v-if="selected === 'Burkina Faso'">
          <div class="h-full">
            <div class="flex items-center h-full tout">
              <input type="text" ref="lettre" @keypress="isNumberOnly" v-model="lettre" class="lettre h-full" id="lettre1" placeholder="12" maxlength="02">
              <input type="text" ref="bf" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" v-model="bf1" class=" lettre h-full" placeholder="BC" maxlength="02">
              <input type="text" ref="chiffre" @keypress="isNumberOnly" v-model="plaque" class="numero h-full" placeholder="2252" maxlength="04">
              <input type="text" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" ref="pays" v-model="pays" class="h-full neutre" placeholder="RB" maxlength="02">
            </div>

            <div class="flex items-center bas">
              <div class="mr-3 tiret" @click="setFocus1">--</div>
              <div class="mr-2 tiret" @click="setBf">--</div>
              <div class="mr-2 tiret" @click="setFocus2">----</div>
              <div class="tiret" @click="setFocus3">--</div>
            </div>
          </div>
        </div>

        <div class="input pl-5 h-full" v-if="selected === 'Côte d\'Ivoire'">
          <div class="h-full">
            <div class="flex items-center h-full tout">
              <input type="text" ref="chiffre" @keypress="isNumberOnly" v-model="plaque" class="numero h-full" placeholder="2252" maxlength="04">
              <input type="text" ref="lettre" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" v-model="lettre" class=" lettre h-full" placeholder="BC" maxlength="02">
              <input type="text" ref="bf" @keypress="isNumberOnly" v-model="bf1" class="lettre h-full" placeholder="12" maxlength="02">
              <input type="text" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" ref="pays" v-model="pays" class="h-full neutre" placeholder="RB" maxlength="02">
            </div>

            <div class="flex items-center bas">
              <div class="mr-2 tiret" @click="setFocus2">----</div>
              <div class="mr-3 tiret" @click="setFocus1">--</div>
              <div class="mr-2 tiret" @click="setBf">--</div>
              <div class="tiret" @click="setFocus3">--</div>
            </div>
          </div>
        </div>

        <div class="input pl-5 h-full" v-if="selected === 'Ghana'">
          <div class="h-full">
            <div class="flex items-center h-full tout">
              <input type="text" ref="lettre" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" v-model="lettre" class="lettre h-full" placeholder="SS" maxlength="02">
              <input type="text" ref="chiffre" @keypress="isNumberOnly" v-model="plaque" class="numero h-full" placeholder="2252" maxlength="04">
              <input type="text" ref="bf" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" v-model="bf1" class="w-6 ghan h-full" placeholder="B" maxlength="01">
              <input type="text" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" ref="pays" v-model="pays" class="h-full neutre" placeholder="RB" maxlength="02">
            </div>

            <div class="flex items-center bas">
              <div class="mr-3 tiret" @click="setFocus1">--</div>
              <div class="mr-2 tiret" @click="setFocus2">----</div>
              <div class="mr-2 tiret" @click="setBf">-</div>
              <div class="tiret" @click="setFocus3">--</div>
            </div>
          </div>
        </div>

        <div class="input pl-5 h-full" v-if="selected === 'Nigeria'">
          <div class="h-full">
            <div class="flex items-center h-full tout">
              <input type="text" ref="chiffre" v-model="plaque" class="numeronig h-full" placeholder="CG254" maxlength="05">
              <input type="text" ref="lettre" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" v-model="lettre" class="lettrenig h-full" placeholder="XDE" maxlength="03">
              <input type="text" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" ref="pays" v-model="pays" class="h-full neutre" placeholder="RB" maxlength="02">
            </div>

            <div class="flex items-center bas">
              <div class="mr-4 tiret" @click="setFocus2">-----</div>
              <div class="mr-2 tiret" @click="setFocus1">---</div>
              <div class="tiret" @click="setFocus3">--</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="verifType() === 0">
        <inputo :value="otherPlaque" @info="retourOrther"/>
      </div>
    </div>
    {{ controleur }}
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'
import selectListe from '@/components/helper/add/selectListe'
import countries from '@/components/cedro/jsonWord/countries.json'

export default {
  name: 'index',
  components: {
    inputo,
    selectListe
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      lettre: '',
      plaque: '',
      pays: '',
      allZone: [],
      selected: null,
      zone: '',
      primaryZone: ['Bénin', 'Ghana', 'Côte d\'Ivoire', 'Niger', 'Nigeria', 'Togo', 'Burkina Faso'],
      otherPlaque: '',
      bf1: ''
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    controleur: function () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.lettre = this.lettre.toUpperCase()
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.bf1 = this.bf1.toUpperCase()
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.otherPlaque = this.otherPlaque.toUpperCase()
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.pays = this.pays.toUpperCase()
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.plaque = this.plaque.toUpperCase()

      if (this.lettre.length === 2 && this.zone !== this.primaryZone[4]) {
        if (this.zone === this.primaryZone[0] || this.zone === this.primaryZone[3] || this.zone === this.primaryZone[1] || this.zone === this.primaryZone[5]) {
          this.setFocus2()
        }
        if (this.zone === this.primaryZone[6]) {
          this.setBf()
        }
        if (this.zone === this.primaryZone[2]) {
          this.setBf()
        }
      }

      if (this.bf1.length === 2) {
        if (this.zone === this.primaryZone[6]) {
          this.setFocus2()
        }
        if (this.zone === this.primaryZone[2]) {
          this.setFocus3()
        }
      }

      if (this.plaque.toString().length >= 4 && this.zone !== this.primaryZone[4]) {
        if (this.zone === this.primaryZone[2]) {
          this.setFocus1()
        } else if (this.zone === this.primaryZone[1]) {
          this.setBf()
        } else {
          this.setFocus3()
        }
      }

      if (this.lettre.length === 3) {
        this.setFocus3()
      }

      if (this.plaque.toString().length >= 5) {
        this.setFocus1()
      }

      let plaque = ''
      if (this.verifType() === 1) {
        // RB, RN, TG
        if (this.zone === this.primaryZone[0] || this.zone === this.primaryZone[3] || this.zone === this.primaryZone[5]) {
          plaque = this.lettre.toUpperCase() + this.plaque.toString() + this.pays.toUpperCase()
        }
        // BF
        if (this.zone === this.primaryZone[6]) {
          plaque = this.bf1.toUpperCase() + this.lettre.toUpperCase() + this.plaque.toString() + this.pays.toUpperCase()
        }
        // CI
        if (this.zone === this.primaryZone[2]) {
          plaque = this.plaque.toUpperCase() + this.lettre.toUpperCase() + this.bf1.toString() + this.pays.toUpperCase()
        }

        // GH
        if (this.zone === this.primaryZone[1]) {
          plaque = this.lettre.toUpperCase() + this.plaque.toUpperCase() + this.bf1.toString() + this.pays.toUpperCase()
        }

        // RN
        if (this.zone === this.primaryZone[4]) {
          plaque = this.plaque.toUpperCase() + this.lettre.toUpperCase() + this.pays.toUpperCase()
        }
      }

      if (this.verifType() === 0) {
        // eslint-disable-next-line no-unused-vars
        plaque = this.otherPlaque.toUpperCase()
      }

      if (plaque.length >= 7) {
        this.$emit('info', plaque)
      }
    }
  },

  created () {
    this.trackPays()
  },

  mounted () {
    if (this.donnes.plaque !== undefined) {
      this.lettre = this.donnes.plaque.substr(0, 2)
      this.plaque = this.donnes.plaque.substr(2, 4)
      this.pays = this.donnes.plaque.substr(6, 2)
    }
  },

  methods: {
    retourOrther (answer) {
      this.otherPlaque = answer
    },

    verifType () {
      if (this.zone !== '' && this.primaryZone.indexOf(this.zone) !== -1) {
        return 1
      } else {
        return 0
      }
    },

    retourInfo (answer) {
      this.zone = answer
      this.plaque = ''
      this.lettre = ''
      this.pays = ''
      this.bf1 = ''
      this.dispatchPays()
    },

    retourSearch (answer) {
      this.selected = answer
      this.zone = answer
    },

    dispatchPays () {
      if (this.selected === this.primaryZone[0]) {
        this.pays = 'RB'
      }
      if (this.selected === this.primaryZone[3] || this.selected === this.primaryZone[4]) {
        this.pays = 'RN'
      }
      if (this.selected === this.primaryZone[6]) {
        this.pays = 'BF'
      }
      if (this.selected === this.primaryZone[2]) {
        this.pays = 'CI'
      }
      if (this.selected === this.primaryZone[1]) {
        this.pays = 'GH'
      }
      if (this.selected === this.primaryZone[5]) {
        this.pays = 'TG'
      }
    },

    trackPays () {
      for (const item in countries) {
        this.allZone.push(countries[item].name)
      }
    },

    setFocus1 () {
      this.$refs.lettre.focus()
    },

    setBf () {
      this.$refs.bf.focus()
    },

    setFocus2 () {
      this.$refs.chiffre.focus()
    },

    setFocus3 () {
      this.$refs.pays.focus()
    },

    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.input{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
}
.input:focus-within {
  border: 1px solid $focus-color;
}
.input:hover {
  border: 1px solid $hover-color;
}
div{
  font-family: $font-default;
}

.tiret{
  font-weight: 500;
  font-size: 20px;
  color: #A9B2C6;
  letter-spacing: 8.5px;
}

input, .neutre{
  font-weight: 500;
  font-size: 20px;
  color: #0e0e0e;
  letter-spacing: 4px;
}
.bas{
  height: 2px;
  margin-top: -10px;
}
.lettre{
  width: 7%;
}
.numero{
  width: 12%;
}
.lettrenig{
  width: 10%;
}
.numeronig{
  width: 16%;
}
.sel{
  width: 99%;
}
@media screen and (max-width: 700px) {
  .lettre{
    width: 16%;
  }
  .numero{
    width: 24%;
  }
  .lettrenig{
    width: 18%;
  }
  .numeronig{
    width: 33%;
  }
 .bas{
   display: none;
  }
  .ghan{
    width: 8%;
    margin-left: 8px;
  }
  .neutre{
    margin-left: 10px;
    width: 20%;
  }
  input{
   background: transparent;
    width: auto;
  }
  .input{
    height: 55px;
  }
}

@media screen and (max-width: 320px) {
  .lettre{
    width: 19%;
  }
  .numero{
    width: 29%;
  }
  .bas{
    display: none;
  }
  .neutre{
    margin-left: 10px;
    width: 20%;
  }
  input{
    background: transparent;
  }
  .lettrenig{
    width: 23%;
  }
  .numeronig{
    width: 42%;
  }
}
</style>
