<template>
<div class="about-page w-full h-screen" id="element">
  <popupCharge :want-to-make-offer="charge"/>
  <header-back-recap :donnes="devis" titre="Modification des informations" height="155px"/>
  <div class="hower mr-auto ml-auto">

    <div class="text-c36 font-bold text-black text-center">Plaque du véhicule</div>
    <plaqueInput class="mt-6" @info="backPlaque"/>

    <div class="flex md:w-full alerte pl-5 pr-5 mt-5 items-center">
      <svg-icon
        name="mdi_alert"
        class="h-6 mr-4"
        original
      />
      <div class="w-full">
        Une fois enregistré vous ne pourrez plus modifier à nouveau le numéro d’immatriculation du véhicule.
      </div>
    </div>

    <div class="mt-6">
      <buton label="Enregistrer" height="53px" background="#0269AD" color="#fff" size="20px" weight="500" @info="save"/>

      <div class="mt-5 text-center text-lg font-semibold cursor-pointer" @click="retourDetail">Annuler</div>
    </div>
  </div>

  {{donnee}}
</div>
</template>

<script>
import plaqueInput from '@/components/helper/form/plaqueInput'
import headerBackRecap from '@/components/helper/headerBackRecap'
import popupCharge from '@/components/popup/popupCharge'
import buton from '@/components/helper/add/button'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'

export default {
  name: 'index',
  components: {
    plaqueInput,
    headerBackRecap,
    popupCharge,
    buton
  },

  props: {
    width: {
      type: String,
      default: '30%'
    }
  },

  data () {
    return {
      souscription: null,
      charge: false,
      plaque: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {

    }
  },
  created () {
    this.souscription = this.$store.getters.souscriptionDetail
    // console.log(this.souscription)
  },
  mounted () {
    this.smooth()
  },

  methods: {
    retourDetail (answer) {
      this.$router.push('/souscription')
    },

    smooth () {
      const element = document.getElementById('element')
      // element.scrollIntoView(true)
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    },

    backPlaque (a) {
      this.plaque = a
    },

    save () {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.updatePlaque, {
        id: this.souscription.id,
        registration: this.plaque
      })
        .then(response => {
          console.log('modification')
          console.log(response)
          this.charge = false
          this.$router.push('/souscription')
        })
        .catch(error => {
          this.charge = false
          console.log('erreur')
          console.log(error)
          if (error.data.statusCode === 401) {
            this.$store.dispatch('logout', null)
            this.$router.push('/login')
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.about-page{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
  background-color: #F5F5F5;
  height: 100%;
  padding-bottom: 300px;
}
.hower{
  width: 80%;
  padding: 50px 230px 50px 230px;
  border-radius: 20px;
}
.alerte{
  height: 73px;
  border-radius: 10px;
  background: #E8E8E8;
  font-size: 14px;
}
.details{
  width: 65%;
  padding-top: 50px;
}
@media screen and (max-width: 700px){
  .hower{
    width: 93%;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    margin-top: -30px;
  }
}
</style>
